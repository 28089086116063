<template>
	<div class="popup active">
        <div class="notice_popup human int">
          <div class="close" @click="$emit('close')">
            <img src="/images/close.png" alt="닫기" />
          </div>
          <div class="member" style="line-height: 35px">
            입력하신 내용으로 <br />
            문의 등록 하시겠습니까?
          </div>
          <div class="select-btn">
            <div class="btn" @click="$emit('close')">취소</div>
            <div class="btn" @click="$emit('close', true)">확인</div>
          </div>
        </div>
    </div>
</template>

<script>
   export default {
    
   }
</script>